// eslint-disable-next-line
export const DEFAULT_PATH_COST_CONFIG = {
  justification: "L",
  locales: "es-ES",
  currency: true,
  currencyIndicator: "EUR",
  percentage: false,
  precision: 0,
  wholenumber: null,
  commafy: true,
  shortFormat: true,
  shortFormatMinValue: 10000,
  title: true
};
// eslint-disable-next-line
export const SET_WEIGHT_ABS = "2";
// eslint-disable-next-line
export const SET_WEIGHT_REL = "1";
// eslint-disable-next-line
export const SET_WEIGHT_NOWEIGHT = "3";
// eslint-disable-next-line
export const MAX_CAT_WEIGHT_NOWEIGHT = 1;
// eslint-disable-next-line
export const MAX_CAT_WEIGHT_ABS = 1000;
// eslint-disable-next-line
export const MAX_CAT_WEIGHT_REL = 100;
// eslint-disable-next-line
export const BUFFER_LAYER_SIGNAL_HIDE = 2;
// eslint-disable-next-line
export const BUFFER_LAYER_SIGNAL_SHOW = 1;
// eslint-disable-next-line
export const COMPLETE_SIGNAL = 0;
// eslint-disable-next-line
export const COLORS_UI = [
  "#FF2D55",
  "#242E45",
  "#00BEF3",
  "#FFCC00",
  "#4CD964"
];
// eslint-disable-next-line
export const COLORS_UI_LIGHTER = [
  "#FF5470",
  "#454D61",
  "#49C8F6",
  "#FFD447",
  "#6EDF7D"
];
// eslint-disable-next-line
export const COLORS_UI_HIGHLIGHTS = [
  "#E0F7FD",
  "#FFF9E5",
  "#EBFBEE",
  "#FFE8EC",
  "#F1E2FE",
  "#FFEEE0"
];
// eslint-disable-next-line
export const COLORS_UI_HIGHLIGHTS_DARKER = [
  "#ADC4CA",
  "#CCC6B2",
  "#B8C8BB",
  "#CCB5B9",
  "#BEAFCB",
  "#CCBBAD"
];

export const DEFAULT_COLOR = "rgba(18, 153, 236, 0.6)";
export const DEFAULT_OPACITY = 0.7;

//PROGRESS ITEM STATES
// a task that failed
export const FAILURE = "FAILURE";
// a task that finished correctly
export const SUCCESS = "SUCCESS";
// a task that finished incorrectly
export const ERROR = "ERROR";
// A task acknowledged by the BE, but not yet started
export const QUEUED = "QUEUED";
// We have no information on this task status
export const UNKNOWN = "UNKNOWN";
// A task is pending state
export const PENDING = "PENDING";
// A task is running state
export const RUNNING = "RUNNING";
// A terminated task with user
export const KILLED = "KILLED";

// SINGLE_MULTI_RING
export const SINGLE_RING = 0;
export const MULTI_RING = 1;

// Key codes
export const EnterKey = 13;
export const EscapeKey = 27;
export const ArrowUpKey = 38;
export const ArrowDownKey = 40;
export const F11 = "F11";

// Version and copyright notice
export const PATHFINDER_VERSION = "4.0.5";
export const PATHFINDER_COPYRIGHT = "© 2017-2024 Gilytics AG";

// Scenario settings
export const SCENARIO_SETTINGS_TAB = 2;

// Layer settings
export const EXCESS_RINGS_AMOUNT = 3;

// Results
export const DEFAULT_CORRIDOR_WIDTH = 10;

export const GEOPROCESS_CATEGORY_NAME = "Geoprocess Output";

export const CONDITIONAL_SET = [
  { value: "EQ", label: "=" },
  { value: "LT", label: "<" },
  { value: "GT", label: ">" },
  { value: "LTE", label: "<=" },
  { value: "GTE", label: ">=" },
  { value: "NEQ", label: "!=" }
];

// User Types
export const T_SUPERUSER = "superuser";
export const T_ADMIN = "admin";
export const T_USER = "user";
export const T_VIEWER = "viewer";

// Menu Modes
export const MENU_SUPERUSER = "m_superuser";
export const MENU_ADMIN = "m_admin";
export const MENU_USER = "m_user";
export const MENU_VIEWER = "m_viewer";

export const getAuthToken = () => {
  const tfaToken = sessionStorage.getItem("tfaToken");

  if (tfaToken) {
    return tfaToken;
  } else {
    return (
      localStorage.getItem("appToken") || sessionStorage.getItem("appToken")
    );
  }
};

export const MAX_SCENARIO_NAME = 50;

// Used map and data projections
export const EPSG_4326 = "EPSG:4326"; // WGS84
export const EPSG_3857 = "EPSG:3857"; // Spherical Mercator projection for Map, avoids distortion

// Routing Algorithms
export const PATHFINDER_EXPLORE = "GeneticAlgorithm";

// Path metadata
export const EDGE_TYPE_OVERHEAD = "O"; //79; // 'O'
export const EDGE_TYPE_TUNNEL = "T"; // 84; // 'T'
export const EDGE_TYPE_UNDER = "U"; //85; // 'U'

// Cookies
export const COOKIE_CONSENT_DECLINED = "declined";
export const COOKIE_CONSENT_ACCEPTED = "accepted";

// Local Settings
export const LOCAL_SETTINGS_KEY_NAME = "pathfinder-local-settings";

// Layout
export const DRAWER_DEFAULT_WIDTH = 330;
export const DRAWER_SUBSECTIONS_OPEN_WIDTH = 925;
export const DRAWER_MIN_WIDTH = 10;
export const DRAWER_MAX_WIDTH = 1250;
export const DRAWER_CLOSING_WIDTH = 290;

export const BOTTOM_DRAWER_DEFAULT_HEIGHT = 330;
export const BOTTOM_DRAWER_MIN_HEIGHT = 10;
export const BOTTOM_DRAWER_MAX_HEIGHT = 750;
export const BOTTOM_DRAWER_CLOSING_HEIGHT = 150;

export const SCENARIO_SELECTOR_DEFAULT_HEIGHT = 210;
export const SCENARIO_SELECTOR_MIN_HEIGHT = 125;
export const SCENARIO_SELECTOR_MAX_HEIGHT = 800;

// Feature Codenames
export const PYLON_LOCATING = "pylon_locating";
export const PATH_TECHNOLOGIES = "path_technologies";

// Comments
export const threadColors = [
  "#00BEF3",
  "#4CD964",
  "#FFCC00",
  "#FF2D55",
  "#CBD2E1",
  "#437DF9"
];

// EDITOR MODES
export const EDITOR_MODES = {
  NO_EDITOR: 0,
  PROJECT_POINTS_EDITOR: 1,
  SCENARIO_POINTS_EDITOR: 2
};

// PATH SECTIONS
export const PATH_EDIT_MODES = {
  SELECT_PATH: "selectPath",
  SELECT_SECTION: "selectSection",
  SELECT_TECHNOLOGY: "selectTechnology",
  SPLIT: "selectNodeSplit",
  JOIN: "selectNodeJoin"
};

// TOOLBOXES
export const MEASUREMENT_2D_TOOLS = "measurements_2d_tools";
export const PYLONS_2D_TOOLS = "pylons_2d_tools";
export const MEASUREMENT_3D_TOOLS = "measurements_3d_tools";
export const PYLONS_3D_TOOLS = "pylons_3d_tools";

export const PATH_TOOLS_2D = {
  INFO_PYLON: "2DInfoPylon",
  MOVE_PYLON: "2DMovePylon",
  ADD_PYLON: "2DAddPylon",
  DELETE_PYLON: "2DDeletePylon"
};

export const CHANGE_PROJECT_STATE = {
  IDLE: "IDLE",
  FINISHED: "FINISHED",
  CHANGING: "CHANGING",
  FAILED: "FAILED"
};

export const CURRENCY_SYMBOLS = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
  CHF: "CHF" // Swiss francs
};

// Constants used by the SridSelector and other components
export const IMPORT_JSON_FALLBACK_DATA = [{ name: "WGS 84", code: 4326 }];
export const DEFAULT_FALLBACK_EPSG =
  IMPORT_JSON_FALLBACK_DATA[0].code.toString();
